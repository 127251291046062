// Configuration settings for Multi-tenant Staging (Non-Prod / Pre-Prod)
export const environment = {
    APP_VERSION_MAJOR: 2,
    APP_VERSION_MINOR: 8,
    APP_VERSION_PATCH: 0,

    PRODUCTION: false,
    STAGING: true,

    NODE_BASE_URL: 'https://api-node.us.staging.engincloud.com',
    NODE_API_URL: 'https://api-node.us.staging.engincloud.com/api',
    PYTHON_API_URL: '',

    OAUTH_CLIENT_ID: '1BVDn1pM8MtUiAQ6s4R7gc3WIjtV7kQsfz4Ex7aM',
    OAUTH_CLIENT_SECRET: 'fMm3BWoTihmrWAb5u0qa8mtFbh9k0PARXFLKAYQh',

    SSO_ENABLED: false,
    AZURE_AD: {
        AUTHORITY: '',
        APPLICATION_ID: '',
        REDIRECT_URL: '',
    },
    SENSITIVE_INFO_LOGIN: false,
    SENSITIVE_INFO_HEADER: false,
};
